import React, { useState, useEffect } from "react";
import { createPortal } from 'react-dom';
import ModalVideo from 'react-modal-video';
import BackgroundImage from 'gatsby-background-image';
import PlayCircleIcon from '@icons/play-circle.inline.svg';
import PlayIcon from '@icons/play.inline.svg';
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import "./assets/modal.scss";

const ModalWindow = ({ children }) => {
    // element to which the modal will be rendered
    const el = document.createElement("div");
    // add a class to the container div for styling
    // el.className = "modal-portal-container";
    useEffect(() => {
        const modalRoot = document.body;
        // append to root when the children of Modal are mounted
        modalRoot.appendChild(el);
        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return(
        createPortal(
            children,
            el
        )
    )
}

const VideoModalImg = (props) => {
    const gtmClick = (name) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'Video Play Btn',
        'formType': 'Video Play Button',
        'formId': 'Video Play',
        'formName': name,
        'formLabel': name
        });
        setOpen(true)
    }
    const [isOpen, setOpen] = useState(false);
    
    return(
        <>
            <div href="#" role="button" tabIndex="0" onClick={gtmClick} onKeyDown={()=> setOpen(true)}  className="video">
                <BackgroundImage
                    Tag="div"
                    className={`video-text-module__image${props.imgClass}`}
                    fluid={props.imgUrl}
                    loading="eager"
                    role="img"
                >
                    <div className="video-icon text-center">
                        {props.hideCircleIcon && <PlayIcon className="icon-play-inline" />}
                        {!props.hideCircleIcon && <PlayCircleIcon className="icon-play-circle-inline" />}
                    </div>
                </BackgroundImage>
            </div>

            {isOpen &&
                <ModalWindow>
                    <ModalVideo className="mx-40" channel={props.videoChannel} isOpen={isOpen} videoId={props.videoId} onClose={() => setOpen(false)} />
                </ModalWindow>
            }
        </>
    )
}

VideoModalImg.defaultProps = {
    hideCircleIcon: false,
    imgClass: " mb-22 mb-md-0 mt-md-60 mt-xl-0"
}

export default VideoModalImg