import React, { useRef, useEffect, useState } from "react";
// import ReactDOM from 'react-dom';
import { navigate } from "@reach/router";
import qs from "qs";
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby";

import "@assets/scss/App.scss";
import  '@components/jobs/assets/jobs.scss';

import Layout from "@components/layout";
import Header from "@components/header/Header";
import VideoTextModule from "@components/modules/video-text/video-text";
import FormSubscribe from "@components/forms/subscribe-form/subscribe-form";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";

import { Container, Col, Row, InputGroup, Form } from "react-bootstrap"
import SearchResultsHeader from "@components/jobs/results/header/header"
// import SearchResultsJobAlert from "@components/jobs/results/header/job-alert"
import JobHit from "@components/jobs/jobHit"

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import SearchIcon from '@icons/search.inline.svg'
// import LocationIcon from '@icons/marker.inline.svg'
import SortIcon from '@icons/sort.inline.svg'
// import PoundIcon from '@icons/pound.inline.svg'
// import FormBtnIcon from '@icons/form-btn.inline.svg'
// import OpenIcon from '@icons/plus.inline.svg';
// import CloseIcon from '@icons/minus.inline.svg';

import algoliasearch from 'algoliasearch/lite';
import {
	InstantSearch,
	Configure,
	connectInfiniteHits,
	connectSearchBox,
	connectRange,
	SortBy,
	connectStats,
	RefinementList
} from 'react-instantsearch-dom';

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const DEBOUNCE_TIME = 500;
const searchClient = algoliasearch(
	`${process.env.GATSBY_ALGOLIA_APP_ID}`,
	`${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
);

const InfiniteHits = ({
	connectStats,
	hits,
	hasPrevious,
	refinePrevious,
	hasMore,
	refineNext
}) => {
	return(
		<section className="section section-jobs">
			<div className="jobs-list">
				{(hits.length > 0 && hasPrevious) && (
					<button disabled={!hasPrevious} onClick={refinePrevious} className="btn btn-outline-secondary d-flex mx-auto mb-30 mb-md-40">
						Show previous
					</button>
				)}
				{hits.map(hit => (
					<JobHit
						key={hit.jobid}
						slug={hit.slug}
						jobid={hit.jobid}
						title={hit.title}
						salary={hit.salary}
						ote={hit.ote}
						currency={hit.currency}
						address={hit.address}
						websitecountry={hit.websitecountry}
					/>
				))}

				{(hits.length > 0 && hasMore) && (
					<button
						onClick={refineNext}
						disabled={!hasMore}
						className="btn btn-outline-secondary d-flex mx-auto mt-30 mt-md-40"
					>
						Show more
					</button>
				)}
			</div>
		</section>
	)
}
const JobInfiniteHits = connectInfiniteHits(InfiniteHits);

const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
	<div className="input_container__location order-lg-0 search-jobs-form__input_container mb-12 mb-md-21 mr-lg-20 mb-lg-0">
		{/* <form noValidate action="" role="search"> */}
			<InputGroup>
				<InputGroup.Prepend>
					<InputGroup.Text>
						<SearchIcon className="icon-search" />
					</InputGroup.Text>
				</InputGroup.Prepend>
				<Form.Control 
					type="search"
					value={currentRefinement}
					onChange={event => refine(event.currentTarget.value)}
					placeholder="Keyword, e.g. Sales or Location"
				/>
				{/* {isSearchStalled ? 'My search is stalled' : ''} */}
			</InputGroup>
		{/* </form> */}
	</div>
);
const CustomSearchBox = connectSearchBox(SearchBox);

const priceData = [
	{
		label: "Any Salary",
		min: "0",
		max: ""
	},
	{
		label: "£10,000 - £14,999",
		min: "10000",
		max: "14999"
	},
	{
		label: "£15,000 - £24,999",
		min: "15000",
		max: "24999"
	},
	{
		label: "£25,000 - £49,999",
		min: "25000",
		max: "49999"
	},
	{
		label: "£50,000 - £79,999",
		min: "50000",
		max: "79999"
	},
	{
		label: "£80,000 +",
		min: "80000",
		max: ""
	}
]

const SalaryBox = ({ currentRefinement, min, max, refine }) => {
	let priceFilter = priceData.filter(x => x.min < max); // filter out the values with specified max > then available max
	let len = priceData.length;
	// adjust the last object in array to convert it to from [price] + value
	const priceFilterAdj = priceData.map((p, i) => {
		if (len === i + 1) { // last object in the array
			//let labelNew = '£' + max.toLocaleString() + ' +';
			//let minNew = max;
			//let maxNew = undefined;
			return {...p};
		}
		return p;
	});
	let priceRange = priceFilterAdj;

	let priceValSelect = "";
	// adding both min,max values with comma separation so we can access both of them when filtering
	if (currentRefinement.min !== undefined) {
		priceValSelect = `${currentRefinement.min},${currentRefinement.max}`
	}
	if (currentRefinement.min === currentRefinement.max) {
		priceValSelect = `${currentRefinement.min},`
	}
	if (currentRefinement.max === undefined) {
		priceValSelect = `${currentRefinement.min},`
	}
	return(
		<div className="order-lg-2 search-jobs-form__input_container input_container__sectors mb-12 mb-md-21 mr-lg-20 mb-lg-0">
			<Form.Group controlId="salary-select">
            	<Form.Label srOnly>Select Salary</Form.Label>
            	<Form.Control
					as="select"
					name="salary"
					custom
					// defaultValue={priceValSelect}
					// value={`${currentRefinement.min},${currentRefinement.max}`}
					value={priceValSelect}
					onChange={event => {
						let targetVal = event.currentTarget.value;
						// split the comma separated string to an array to access min and max values
						let targetValArr = targetVal.split(',');
						// show all results
						if (targetValArr[0] === "0" && targetValArr[1] === "") {
							refine({
								min: 0,
								max: max
							})
						// show last results with max set to largest max value
						} else if (targetValArr[1] === "") {
							refine({
								min: targetValArr[0],
								max: max
							})
						// normal filtering defined in the array
						} else {
							refine({
								min: targetValArr[0],
								max: targetValArr[1]
							})
						}
					}}
				>
					{priceRange.map(item => (
						<option
							key={item.label}
							value={[item.min,item.max]}
						>
							{item.label}
						</option>
					))}
				</Form.Control>
			</Form.Group>
		</div>
	)
}
const CustomSalaryBox = connectRange(SalaryBox);

const priceDataOte = [
	{
		label: "Any OTE",
		min: "0",
		max: ""
	},
	{
		label: "£10,000 - £14,999",
		min: "10000",
		max: "14999"
	},
	{
		label: "£15,000 - £24,999",
		min: "15000",
		max: "24999"
	},
	{
		label: "£25,000 - £49,999",
		min: "25000",
		max: "49999"
	},
	{
		label: "£50,000 - £79,999",
		min: "50000",
		max: "79999"
	},
	{
		label: "£80,000 +",
		min: "80000",
		max: ""
	}
]
const OteBox = ({ currentRefinement, min, max, refine }) => {
	let priceFilter = priceDataOte.filter(x => x.min < max); // filter out the values with specified max > then available max
	let len = priceDataOte.length;
	// adjust the last object in array to convert it to from [price] + value
	const priceFilterAdj = priceDataOte.map((p, i) => {
		if (len === i + 1) { // last object in the array
			//let labelNew = '£' + max.toLocaleString() + ' +';
			//let minNew = max;
			//let maxNew = undefined;
			return {...p};
		}
		return p;
	});
	let priceRange = priceFilterAdj;

	let priceValSelect = "";

	// adding both min,max values with comma separation so we can access both of them when filtering
	if (currentRefinement.min !== undefined) {
		priceValSelect = `${currentRefinement.min},${currentRefinement.max}`
	}
	if (currentRefinement.min === currentRefinement.max) {
		priceValSelect = `${currentRefinement.min},`
	}
	if (currentRefinement.max === undefined) {
		priceValSelect = `${currentRefinement.min},`
	}
	return(
		<div className="order-lg-3 search-jobs-form__input_container input_container__sectors mb-12 mb-md-21 mr-lg-20 mb-lg-0">
			<Form.Group controlId="ote-select">
            	<Form.Label srOnly>Select OTE</Form.Label>
            	<Form.Control
					as="select"
					name="ote"
					custom
					// defaultValue={priceValSelect}
					// value={`${currentRefinement.min},${currentRefinement.max}`}
					value={priceValSelect}
					onChange={event => {
						let targetVal = event.currentTarget.value;
						// split the comma separated string to an array to access min and max values
						let targetValArr = targetVal.split(',');
						console.log(targetValArr)
						// show all results
						if (targetValArr[0] === "0" && targetValArr[1] === "") {
							refine({
								min: 0,
								max: max
							})
						// show last results with max set to largest max value
						} else if (targetValArr[1] === "") {
							refine({
								min: targetValArr[0],
								max: max
							})
						// normal filtering defined in the array
						} else {
							refine({
								min: targetValArr[0],
								max: targetValArr[1]
							})
						}
					}}
				>
					{priceRange.map(item => (
						<option
							key={item.label}
							value={[item.min,item.max]}
						>
							{item.label}
						</option>
					))}
				</Form.Control>
			</Form.Group>
		</div>
	)
}
const CustomOteBox = connectRange(OteBox);

const Stats = ({ nbHits }) => (
	<>
		{nbHits} jobs in London
	</>
);
const CustomStats = connectStats(Stats);

const NoStats = ({ nbHits }) => {
	return (
	  	<>
			{nbHits === 0 &&
				<div className="text-center mb-5">
					<div>We are sorry that there are no Jobs that match your criteria. Please refine your search and try again.</div>
				</div>
			}
	  	</>
	)
}
const CustomNoStats = connectStats(NoStats)

const createURL = state => {
	const isDefaultRoute =
		!state.query &&
		state.page === 1 &&
		(state.range && state.range.salary.length && state.range.ote.length);

	if (isDefaultRoute) {
	  	return '';
	}

	let searchPathOrig = '';
	let searchPath = '';
	let salaryPath = '';
	let otePath = '';
	let sortPath = '';
	let pagePath = '';

	if (state.range) {

		// this range is the default one - all salaries
		if (state.range.salary) {
			if (state.range.salary.min === 0 && state.range.salary.max === undefined) {
				salaryPath = '';
			} else if (state.range.salary.max === undefined) { // this is the last value where max is unlimited
				salaryPath = `salary-over-${state.range.salary.min}/`;
			} else {
				salaryPath = `salary-between-${state.range.salary.min}-and-${state.range.salary.max}/`;
			}
		}

		if (state.range.ote) {
			if (state.range.ote.min === 0 && state.range.ote.max === undefined) {
				otePath = '';
			} else if (state.range.ote.max === undefined) { // this is the last value where max is unlimited
				otePath = `ote-over-${state.range.ote.min}/`;
			} else {
				otePath = `ote-between-${state.range.ote.min}-and-${state.range.ote.max}/`;
			}
		}
	}

	// define the slug of sortby options
	// the state.sortBy is defined in algolia
	if (state.sortBy) {
		if( state.sortBy === 'salary_low_high') {
			sortPath = 'sortby-price-asc/';
		}
		if( state.sortBy === 'salary_high_low') {
			sortPath = 'sortby-price-desc/';
		}
	}

	const queryParameters = {};
  
	if (state.query) {
		// replace the spaces in URL with a + sign
		searchPathOrig = state.query.replace(' ', '+')
		// we're not using algolia query as we need to control the URL slug
		searchPath = `search-${encodeURI(searchPathOrig)}/`;
	}
	if (state.page !== 1) {
		pagePath = `page-${state.page}/`;
	}

	const queryString = qs.stringify(queryParameters, {
		addQueryPrefix: true,
		arrayFormat: 'repeat',
	});
	return `/job-search/${queryString}${searchPath}${salaryPath}${otePath}${pagePath}${sortPath}`;
};
  
const searchStateToUrl = searchState =>
  	searchState ? createURL(searchState) : '';

const urlToSearchState = location => {
	let pathUri_main = location.pathname.split("/job-search");

	let query = '';
	let searchVal = '';
	let oteVal = '';
	let pageVal = '';
	let sortVal = '';
	let sortVal_filt = '';
	let minpriceVal = '';
    let maxpriceVal = '';
	let minoteVal = '';
    let maxoteVal = '';

	if (pathUri_main[1]) {
		// split the URL by "/" to have an array that we can iterate over
		let pathUri = pathUri_main[1].split("/");

		for (let vi = 1; vi <= pathUri.length; vi++) {

			if (typeof pathUri[vi] === "undefined") {
				continue
			}

			// if the index value starts with search- we used the Algolia text search
			if (pathUri[vi].indexOf("search-") >= 0) {
				// we replace the prefix to only have the value
				searchVal = pathUri[vi].replace("search-", "")
			}
			// here we detect different price ranges from the custom range select box
			if (
				pathUri[vi].indexOf("salary-between-") >= 0 ||
				pathUri[vi].indexOf("salary-over-") >= 0
			) {
				let priceFilt1 = pathUri[vi].split("salary-over-")
				if (priceFilt1[1]) {
					minpriceVal = priceFilt1[1]
					maxpriceVal = ''
				}
				let priceFilt3 = pathUri[vi].split("salary-between-")
				if (priceFilt3[1]) {
					let priceFilt4 = priceFilt3[1].split("-and-")
					minpriceVal = priceFilt4[0]
					maxpriceVal = priceFilt4[1]
				}
			}
			if (
				pathUri[vi].indexOf("ote-between-") >= 0 ||
				pathUri[vi].indexOf("ote-over-") >= 0
			) {
				let oteFilt1 = pathUri[vi].split("ote-over-")
				if (oteFilt1[1]) {
					minoteVal = oteFilt1[1]
					maxoteVal = ''
				}
				let oteFilt3 = pathUri[vi].split("ote-between-")
				if (oteFilt3[1]) {
					let oteFilt4 = oteFilt3[1].split("-and-")
					minoteVal = oteFilt4[0]
					maxoteVal = oteFilt4[1]
				}
			}
			if (pathUri[vi].indexOf("sortby-") >= 0) {
				// its sortby
				sortVal_filt = pathUri[vi].replace("sortby-", "")
				if(sortVal_filt === "price-asc") {
				  	sortVal = "salary_low_high"
				}
				if(sortVal_filt === "price-desc") {
				  	sortVal = "salary_high_low"
				}
			}
			// page
			if (pathUri[vi].indexOf("page") >= 0) {
				pageVal = pathUri[vi].replace("page-", "")
			}
		}
	}

	if (searchVal) {
		query += `&query=${decodeURIComponent(searchVal)}`
	}

	if (minpriceVal) {
		query += `&range[salary][min]=` + minpriceVal
	}
	
	if (maxpriceVal) {
		query += `&range[salary][max]=` + maxpriceVal
	}

	if (minoteVal) {
		query += `&range[ote][min]=` + minoteVal
	}
	
	if (maxoteVal) {
		query += `&range[ote][max]=` + maxoteVal
	}

	if (pageVal) {
		query += `&page=${pageVal}`
	}

  	if (sortVal) {
    	query += `&sortBy=` + sortVal
	}

  	return qs.parse(query)
};

// class AlgoliaSearchResults extends React.Component {
const AlgoliaSearchResults = (props) => {
	
	const [searchState, setSearchState] = useState(urlToSearchState(props.location));
	const [debouncedSetState, setDebouncedSetState] = useState(null);

	const onSearchStateChange = updatedSearchState => {
		clearTimeout(debouncedSetState);

		setDebouncedSetState(
			setTimeout(() => {
				navigate(searchStateToUrl(updatedSearchState), updatedSearchState);
			}, DEBOUNCE_TIME)
		);

		setSearchState(updatedSearchState);
	};

	let container = useRef(null);
    let searchActions = useRef(null);
	let searchAnim = useRef(null);

	useEffect(() => {
		gsap.fromTo(searchAnim, {
			y: -70,
            autoAlpha: 0
		},{
			y: 0,
            autoAlpha: 1,
            duration: 1.5,
            scrollTrigger: {
                trigger: container,
                start: 'top 70%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
		})
		gsap.fromTo(searchActions, {
			x: 100,
            autoAlpha: 0
		},{
			x: 0,
            autoAlpha: 1,
            duration: 1.5
		})
	}, [])

	return (
		<div className="ais-InstantSearch">
			<InstantSearch
				indexName="jobs"
				searchClient={searchClient}
				searchState={searchState}
				onSearchStateChange={onSearchStateChange}
				createURL={createURL}
				routing="true"
			>
				<div className="form-dark">
					<div className="search-jobs-form-container" ref={el => { container = el; }}>
						<Container>
							<Row>
								<Col xs={12}>
									<div className="search-jobs-form d-flex flex-column flex-lg-row mt-25 mb-12 my-lg-40"  ref={el => { searchAnim = el; }}>
										<div className="d-none">
											<RefinementList
												attribute="status"
												defaultRefinement={['true']}
											/>
										</div>
										<CustomSearchBox />
										<CustomSalaryBox attribute="salary" min={0} />
										<CustomOteBox attribute="ote" min={0} />
										{/* <div className="font-weight-bold d-md-none mb-12">
											More Filters <OpenIcon />
										</div> */}
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</div>

				<div className="grey-bg pt-23 pb-25 pt-md-32 pb-md-40 pb-xl-50">
					<Container>
						<Row>
							<Col xs={12}>
								<Row className="mb-26 mb-md-35">
									<Col xs={12} lg={6} xl={7}>
										<SearchResultsHeader
											Title={<CustomStats />}
											titleClass=" mb-20 mb-md-13 mb-lg-0"
										/>
									</Col>
									<Col xs={12} lg={6} xl={5} className="search-jobs-header d-flex flex-column flex-sm-row align-items-start align-items-sm-end justify-content-lg-end" ref={el => { searchActions = el; }}>
										{/* <SearchResultsJobAlert /> */}
										<div className="sort-by">
											<SortIcon />
											<SortBy
												defaultRefinement="jobs"
												items={[
													{ value: 'most_recent', label: 'Most Recent First' },
													{ value: 'salary_low_high', label: 'Salary - low to high' },
													{ value: 'salary_high_low', label: 'Salary - high to low' }
												]}
											/>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<JobInfiniteHits />
								<CustomNoStats />
							</Col>
						</Row>
					</Container>
				</div>
				<Configure hitsPerPage={15} />
			</InstantSearch>
		</div>
	)
}

const JobSearchPage = ({location}, props) => {
	const imgUrl = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "Homepage-More-About-Crux.png" }) {
				childImageSharp {
					fluid(quality: 80, maxWidth: 660) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	`)

	const btnData = [
        {
            id: 1231,
            externalLink: null,
            Label: 'More about Crux',
            targetLink: '/about',
            btnClass: 'btn btn-primary'
        }
    ]

	return(
		<Layout>
				<SEO
					title="Jobs"
					description="Don’t miss out on great <Job Title> jobs near <Location> with 	ux Careers. Crux Careers specialise in <Job Title> recruitment in London and the UK."
				/>
				<Header />
				<div className="iframe-blk">
				<iframe style={{height:'1300px',width:'100%', maxWidth:'90%', maxHeight :'90%', border:'none', margin:'auto', textAlign:'center', display:'block'}} src="https://recruitcrm.io/jobs/Crux_Careers_1_jobs"> </iframe>
				</div>
				{/* <AlgoliaSearchResults
					location={location}
				/> */}
				<VideoTextModule
					Title="We don’t just fill vacancies. We build careers and grow businesses."
					Text="<p>Candidates trust us to find them the best job opportunities within the property industry. Businesses know we are experienced, honest, flexible and in it for the long term.</p>"
					imgUrl={imgUrl ? imgUrl.file.childImageSharp.fluid : null}
					ButtonData={btnData}
					// moduleData={videoModule}
					// showVideo={videoModule.Video ? videoModule.Video.showVideo : null}
					// videoId={videoModule.Video ? videoModule.Video.videoId : null}
					// videoChannel={videoModule.Video ? videoModule.Video.videoChannel : null}
					// sectionClass={Modules.sectionClass}
				/>
				<FormSubscribe />
				<PopularSearch {...props}
					sectionClass=" section-grey-bg"
				/>
				<Footer />
		</Layout>
	)
}

JobSearchPage.propTypes = {
	location: PropTypes.object.isRequired,
};

export default JobSearchPage
